@tailwind base;
@tailwind components;
@tailwind utilities;




body{
    width: 100vw;
    overflow-x: hidden;
    background-color: #fff;
    color: black;
}


h3{
  @apply text-[24px] sm:text-[26px] md:text-[28px] lg:text-[40px] font-zen py-[1em];
  -webkit-text-fill-color: transparent;
  background: linear-gradient(20deg, #FF8000 11.71%, #FFD900 50.36%);
  background-clip: text;
    -webkit-background-clip: text;

}
p{
  @apply text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px]  text-left font-light;
}
.flexCol{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.containerr{
  @apply py-[30px] sm:py-[50px] px-[5vw] sm:px-[10vw];
}
.header {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100000;
    overflow: hidden;
  
  }


  #burger-menu {
    cursor: pointer;
    height: 29px;
    width: 40px;
    margin: 0 5vw;
    overflow: visible;
    position: relative;
    z-index: 2;
  }
  
  #burger-menu span,
  #burger-menu span:before,
  #burger-menu span:after {
    display: block;
    height: 5px;
    opacity: 1;
    position: absolute;
    transition: 0.3s ease-in-out;
    background: #FF8000;

  }

  
  
  #burger-menu span:before,
  #burger-menu span:after {
    content: "";
  }
  
  #burger-menu span {
    right: 0px;
    top: 13px;
    width: 40px;
  }
  
  #burger-menu span:before {
    left: 0px;
    top: -12px;
    width: 29px;
  }
  
  #burger-menu span:after {
    left: 0px;
    top: 12px;
    width: 33px;
  }
  
  #burger-menu.close span {
    transform: rotate(-45deg);
    top: 13px;
    width: 40px;
  }
  
  #burger-menu.close span:before {
    top: 0px;
    transform: rotate(90deg);
    width: 40px;
  }
  
  #burger-menu.close span:after {
    top: 0px;
    left: 0;
    transform: rotate(90deg);
    opacity: 0;
    width: 0;
  }
  
  #menu {
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  
  #menu.overlay {
    visibility: visible;
    opacity: 0.95;
  
    background: #1E1E1E;
  }
  
  #menu li {
    list-style: none;
    display: block;
    text-decoration: none;
    text-align: left;
  
  }


  .gradient-border {
  font-weight: 800;
  /* Warning: no fallback */
  padding: 0 10px;
  
  background: -webkit-linear-gradient(-86deg, #FF8000, #FFD900);
  -webkit-background-clip: text;
  -webkit-text-stroke: 7px transparent;
  letter-spacing: 0.03em;
  color: #fff;
  
}

.text-gradient {



background: linear-gradient(93.2deg, #FF8000 11.71%, #FFD900 100.36%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;


}


.fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  
  .fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }






  html.has-scroll-smooth {
    overflow: hidden;
  }
  html.has-scroll-dragging,
  html.has-scroll-dragging * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .has-scroll-smooth body {
    overflow: hidden;
  }


  .gradient-btn{
    background: linear-gradient(90deg, #FFC107, #FF9800);
    display: inline-block;
  }
  .gradient-btn svg{
    transition: 0.2s ease-out;
  }
  .gradient-btn:hover svg{
      transform: rotate(45deg);
  }


  .rotatee{
    display: inline-block; /* Needed for transform to work */
    transform: rotate(-90deg);

  }




.text-gradient-sm{
  background: linear-gradient(93.2deg, #FF8000 11.71%, #FFD900 60.36%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}





.panel {

  @apply ml-[-100vw] mt-[0.7em] w-[400vw] bg-[black]  text-[white]   flex ;
}
.valuesTitle{
  @apply text-[80px] md:text-[96px] lg:text-[120px] relative flex flex-col justify-end;

}
.valuesTitle p{
  position: absolute;
transform: rotate(-90deg);
font-weight: 800;
  /* Warning: no fallback */
  @apply text-[80px] md:text-[96px] lg:text-[120px] ;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  letter-spacing: 0.03em;
  color: #fff;
  transform-origin: 0% 0%;
  

}
.underline-animation{
  position: relative; 
}

.underline-animation::before {
  content: ""; 
  position: absolute;
  width: 0; 
  height: 2px; 
  background-color: currentColor; 
  bottom: 0; 
  left: 0; 
  transition: width 0.3s; 
}

.underline-animation:hover::before {
  width: 100%; 
}

.activeMenu{
  font-weight: 500;
}


.servicesList li{
  list-style-type: "+  ";
  padding: 0.3em 0;
}
.solidBorderText{
  @apply text-[60px] md:text-[96px] lg:text-[120px] ;
  background: black;
  font-weight: 800;

  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  letter-spacing: 0.03em;
  color: #fff;
  line-height: 1em;
}
.btn{
  @apply border-[1px] border-[#FF8000] text-[#FF8000]  rounded-[10px] font-[300];
  transition: 0.2s ease-in;
}
.btn:hover{
  @apply bg-[#FF8000] text-[white];
}
.btn-solid{
  @apply border-[1px] border-[#FF8000] bg-[#FF8000] text-[white]  rounded-[10px] font-[300];
}
.greyScale{
  filter: grayscale(100);
  transition: 0.4s ease-in-out;
}
.greyScale:hover{
  filter: grayScale(0);
}

.dark-overlay{
  position: absolute;
  width: 100%;
  height: 100%; 
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.85) 20.29%, rgba(0, 0, 0, 0.3) 100%);
  /* background-color: rgba(0, 0, 0, 0.7); */

}
